@charset "utf-8";

html {
    scroll-behavior: smooth;
}

// #top {
//     background-image: url("/images/p-az.png");
//     background-repeat: no-repeat;
//     background-position: calc(100% + 140px) calc(100% + 140px);
//     background-size: contain;
// }

// Our variables
$base-font-family: Noto;
$base-font-size: 18px;
$small-font-size: $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit: 30px;

$text-color: #111;
$background-color: black;
$brand-color: #2a7ae2;

$grey-color: #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark: darken($grey-color, 25%);

// Width of the content area
$content-width: 800px;

$on-palm: 600px;
$on-laptop: 800px;

:root {
  --app-height: 100%;
}

body {
    background-color: $background-color;
    color: white;
}

pre {
  background-color: black !important;
  color: white;
}

text-black {
  color: black;
}

@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

@import "bootstrap/bootstrap";
@import "infinite-slider";
@import "layout";
@import "rouge-vim.css";

@media screen and (max-width: 500px) {
    .mobile-hidden {
        display: none;
    }
}

#top {
    border-width: 0 0 13px 0;
}

#mainHeader {
    -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);

    .navbar-inner {
        background: #444;
        filter: none !important;
    }
}

.background-black {
  background-color: black;
}

.text-break {
    overflow-wrap: break-word;
    word-break: break-word;
}

@media screen and (min-width: 421px) {
    #top {
        min-height: 18rem;
    }
}

@media screen and (max-width: 420px) {
    #top {
        min-height: 10rem;
    }
}

.top-1 {
    font-size: 5.5vw;
}

.top-2 {
    font-size: 3.4vw;
}

.top-3 {
    font-size: 3vw;
}

.top-4 {
    font-size: 2.2vw;
}

.top-5 {
    font-size: 2.1vw;
}

.top-6 {
    font-size: 1.9vw;
}

@media screen and (max-width: 768px) {
    .top-1 {
        font-size: 42px;
    }

    .top-2 {
        font-size: 26px;
    }

    .top-3 {
        font-size: 24px;
    }

    .top-4 {
        font-size: 20px;
    }

    .top-5 {
        font-size: 18px;
    }

    .top-6 {
        font-size: 16px;
    }

    #top {
        background-size: 200px;
        background-position: calc(100% + 90px) calc(100% + 90px);

    }

    .lh-1_4 {
        line-height: 1.4 !important;
    }

    .lh-1_3 {
        line-height: 1.3 !important;
    }

    .lh-1_2 {
        line-height: 1.2 !important;
    }
}

@media screen and (min-width: 1025px) {
    .top-1 {
        font-size: 64px;
    }

    .top-2 {
        font-size: 42px;
    }

    .top-3 {
        font-size: 38px;
    }
}

.text-lightcyan {
    color: lightcyan;
}

.display-5 {
    font-size: 1.7rem;
    font-weight: 300;
}

.zoom {
    transition: transform .5s ease;
}

.zoom:hover {
    transform: scale(1.1);
    filter: brightness(95%);
    z-index: 1;
}

.mw-1024 {
    max-width: 1024px;
}

.mw-800 {
    max-width: 800px;
}

#features-content p {
    font-size: 1rem;
}

.mw-keypoint {
    max-width: 400px;
}

.iframe-parent {
  height: calc(var(--vh, 1vh) * 100 - 64px); // see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  border: none;
}

iframe {
  margin-top: 0; // fix position about navbar in ios safari
  height: calc(var(--vh, 1vh) * 100 - 64px); // see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  width: 100%;
  border: none;
}

.hline {
  position: absolute;
  top: 150px;
  width:100%;
  height:5px;
  background: red
}

.bg-white {
  background-color: rgb(233, 226, 226);
}